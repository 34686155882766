import { useParams } from 'react-router-dom';

import { useGetPackagesQuery } from 'data/slices/packageListing';
import { useGetSelectedPackageQuery } from 'data/slices/packageListing/api';
import {
  GetSelectedPackageResponse,
  SelectedPackageDetail,
} from 'data/slices/packageListing/interfaces';
import {
  useGetLeadSelector,
  useGetProductSelector,
} from 'presentation/redux/selectors/lead';
import SessionStorage from 'shared/helper/SessionStorage';
import { getLeadIdFromPath } from 'shared/helper/utilities';
import { Package } from 'shared/types/packages';

import { TransformedPackageType } from 'presentation/pages/car-insurance/PackageListingPage/hooks/useTransformedPackages';
import { FilterInterface } from 'presentation/pages/car-insurance/PackageListingPage/PackageFilter/interface';
import { defaultFiltervalue } from 'presentation/pages/car-insurance/PackageListingPage/PackageFilter/packageFilter.helper';
import transformPackages from 'presentation/pages/car-insurance/PackageListingPage/packageTransformation';

const FILTER_VALUE_STORAGE_KEY = 'PACKAGE_FILTER';

export const saveFilterValuesToStorage = (filter: FilterInterface) => {
  const leadId = getLeadIdFromPath();
  const storage = new SessionStorage();
  storage.setItemByKey(
    `${FILTER_VALUE_STORAGE_KEY}-${leadId}`,
    JSON.stringify(filter)
  );
};

export const getFilterValueFromStorage = () => {
  const leadId = getLeadIdFromPath();
  const storage = new SessionStorage();

  try {
    return JSON.parse(
      storage.getItemByKey(`${FILTER_VALUE_STORAGE_KEY}-${leadId}`)
    ) as FilterInterface;
  } catch {
    return undefined;
  }
};

export const removeFilterValueFromStorage = () => {
  const leadId = getLeadIdFromPath();
  const storage = new SessionStorage();
  storage.removeItemByKey(`${FILTER_VALUE_STORAGE_KEY}-${leadId}`);
};

interface FilterValues {
  paymentOption?: string;
  paymentMethod?: string;
  installment?: number | undefined;
  insuranceKind: string;
  sumInsured?: {
    min: string;
    max: string;
  };
}

function useGetPackageData(packageIds: string[], filterValues: FilterValues) {
  const { id: leadId } = useParams<{ id: string }>();
  const product = useGetProductSelector();
  const lead = useGetLeadSelector();
  const { data, isLoading } = useGetPackagesQuery(
    {
      productType: product,
      leadId: leadId!,
      sumInsuredMin: filterValues?.sumInsured?.min,
      sumInsuredMax: filterValues?.sumInsured?.max,
      paymentOption: filterValues?.paymentOption ?? 'FULL_PAYMENT',
      installment: filterValues?.installment ?? 1,
    },
    { skip: packageIds.length === 0 }
  );

  const { data: selectedPackage, isLoading: isSelectedPackageLoading } =
    useGetSelectedPackageQuery(
      { leadId: leadId!, enableDiscountPricing: true },
      {
        skip: packageIds.length !== 0,
      }
    );

  const selectedPackageData = selectedPackage?.carPackageWithPricing ?? {};

  const getPackages = () => {
    let packages: TransformedPackageType[];
    if (packageIds.length === 0) {
      const selected = (
        selectedPackageData?.package ? [selectedPackageData.package] : []
      ) as Package[];
      packages = selected
        ? transformPackages(selected, {
            ...defaultFiltervalue,
            insuranceCategory: lead.data?.insuranceKind ?? 'both',
          })
        : [];
    } else {
      const filteredPackages = (data?.packages ?? []).filter((pkg) =>
        packageIds.includes(pkg.name)
      );
      packages = filterValues
        ? transformPackages(filteredPackages, {
            insuranceCategory: filterValues.insuranceKind,
          } as FilterInterface)
        : [];
    }
    return packages;
  };

  return {
    packages: getPackages(),
    sumInsuredInfo: {
      min: (selectedPackageData?.sumInsuredMin ?? 0) / 100,
      max: (selectedPackageData?.sumInsuredMax ?? 0) / 100,
    },
    isLoading: isLoading || isSelectedPackageLoading,
    carDetails: data?.carDetails || selectedPackageData?.carDetails,
  };
}

export default useGetPackageData;
