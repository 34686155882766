import { ADD_LEAD_SCHEMA_ID, LEAD_TYPE } from 'shared/constants';
import { formatE164 } from 'shared/helper/utilities';

import { ProductTypeFilter } from '../../../config/TypeFilter';

export interface ISelect {
  id?: number;
  title?: string;
  value?: string;
}

export interface IFormValue {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  product: string;
  source: ISelect;
  reference: string;
  fixedDriver: number;
}
export const PAGE_SIZE_GET_SOURCE = 1000;

export const firstProduct = ProductTypeFilter[0].value;
export const addLeadInitialValue = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  product: firstProduct,
  reference: '',
  source: {},
  fixedDriver: 0,
};

export const inputNumberOnly = (event: KeyboardEvent) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const inputAlphaNumericOnly = (event: KeyboardEvent) => {
  if (!/^[a-zA-Z0-9 ]*$/.test(event.key)) {
    event.preventDefault();
  }
};

export const customFormValue = (formValue: IFormValue) => {
  // INFO: after that, need to get schema id from api
  const phoneDefaultStatus = 'unverified';
  return {
    schema: ADD_LEAD_SCHEMA_ID,
    data: {
      customerFirstName: formValue?.firstName?.trim() ?? '',
      customerLastName: formValue?.lastName?.trim() ?? '',
      customerPhoneNumber: [
        {
          phone: formatE164(formValue?.phone?.trim()),
          status: phoneDefaultStatus,
        },
      ],
      primaryPhoneIndex: 0,
      customerEmail: formValue.email ? [formValue.email.trim()] : [],
      customerPolicyAddress: [],
      customerShippingAddress: [],
      customerBillingAddress: [],
      numberOfFixedDriver: formValue.fixedDriver,
      locale: 'th-th',
    },
    type: LEAD_TYPE.NEW,
    reference: formValue?.reference?.trim() ?? '',
    source: formValue.source.value,
  };
};
