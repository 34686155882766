import { useNewRelic } from '@careos/newrelic';
import { useFlagsmith } from 'flagsmith/react';
import React, { useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useGetAuthenticateQuery,
  useUpdateLastLoginMutation,
} from 'data/slices/authSlice';
import presentRoute from 'presentation/components/controls/Services/serviceHandleRoute';
import LocalStorage, { LOCALSTORAGE_KEY } from 'shared/helper/LocalStorage';
import SessionStorage, {
  SESSION_STORAGE_KEY,
} from 'shared/helper/SessionStorage';
import { PRODUCTS } from 'config/TypeFilter';
import { useAppSelector } from 'presentation/redux/hooks/typedHooks';

import {
  UserRoleID,
  MY_LEADS_URL,
  PERMISSION_DENIED_URL,
  HEALTH_LEAD_URL,
} from './ProtectedRouteHelper';

import { intervalObservable, triggerEventObservable } from '../../app.helper';
import { RabbitResource } from '../../data/gateway/api/resource';
import Loading from '../../Loading';
import { updatePresence } from '../redux/actions/presence';
import { authorizeSuccess } from '../redux/slices/auth';

const localStorage = new LocalStorage();
const sessionStorage = new SessionStorage();
const previousPathKey = SESSION_STORAGE_KEY.PREVIOUS_PAGE;

function ProtectedRoute({
  component: Component,
  layout: Layout,
  permission,
  ...rest
}: any) {
  const {
    data: user,
    error,
    isSuccess,
    isLoading: isLoadingPage,
  } = useGetAuthenticateQuery();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const previousPath = sessionStorage.getItemByKey(previousPathKey);
  const flagsmith = useFlagsmith();
  const [updateLastLogin] = useUpdateLastLoginMutation();
  const dispatch = useDispatch();
  const globalProduct = useAppSelector(
    (state) => state.typeSelectorReducer.globalProductSelectorReducer.data
  );

  const { nrAgent } = useNewRelic();

  const isHealthProduct = globalProduct === PRODUCTS.HEALTH_PRODUCT_INSURANCE;
  useEffect(() => {
    if (user && isSuccess) {
      dispatch(authorizeSuccess(user));
      // Pass user identity to Flagsmith BE
      flagsmith.identify(user.humanId);

      nrAgent.setUserAttributes({
        humanId: user.humanId,
        firstName: user.firstName,
        lastName: user.lastName,
        userId: user.name,
        role: user.role,
      });

      if (!localStorage.getItemByKey(LOCALSTORAGE_KEY.USER_ID)) {
        updateLastLogin({ name: user.name });
        localStorage.setItemByKey(
          LOCALSTORAGE_KEY.USER_ID,
          user.name.replace(/^users\//, '')
        );
      }
    }
  }, [dispatch, user, isSuccess, updateLastLogin, flagsmith, nrAgent]);

  useEffect(() => {
    if (user) {
      let interactTime = new Date().toISOString();
      const interactTimeSubscription = triggerEventObservable().subscribe(
        () => {
          interactTime = new Date().toISOString();
        }
      );
      const intervalTimeSubscription = intervalObservable().subscribe(() => {
        const body = {
          interactTime,
        };
        dispatch(updatePresence(body, user.name));
      });
      return () => {
        interactTimeSubscription.unsubscribe();
        intervalTimeSubscription.unsubscribe();
      };
    }
    return () => null;
  }, [updatePresence, user]); // eslint-disable-line

  const renderRedirectPage = () => {
    let redirectURL = '';
    if (user?.role && rest.path) {
      if (isHealthProduct) {
        redirectURL =
          rest.path === '/' ? HEALTH_LEAD_URL : PERMISSION_DENIED_URL;
        navigate(redirectURL);
        return null;
      }
      if (user.role === UserRoleID.SalesAgent) {
        redirectURL = rest.path === '/' ? MY_LEADS_URL : PERMISSION_DENIED_URL;
        navigate(redirectURL);
        return null;
      }
      redirectURL = PERMISSION_DENIED_URL;

      navigate(redirectURL);
      return null;
    }
    navigate(redirectURL);
    return null;
  };

  const isPermittedRoute = () => permission.includes(user?.role);

  useEffect(() => {
    presentRoute.next(rest.path);
  }, [rest.path]);

  if (!!error && 'status' in error && error.status === 401) {
    sessionStorage.setItemByKey(previousPathKey, pathname);
    window.location.replace(RabbitResource.Auth.getLoginUrl());
  }

  if (isLoadingPage || !user) {
    return <Loading />;
  }

  if (isPermittedRoute() && previousPath && previousPath !== '') {
    sessionStorage.setItemByKey(previousPathKey, '');
    navigate(previousPath);
    return null;
  }

  if (isPermittedRoute() && isHealthProduct && rest.path === '/') {
    renderRedirectPage();
    return null;
  }

  return isPermittedRoute() ? (
    <Layout>
      <Component authInfo={user} />
    </Layout>
  ) : (
    renderRedirectPage()
  );
}

export default memo(ProtectedRoute);
